import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import { render, hydrate } from 'react-dom'
import Container from 'Container'

import { loadableReady } from '@loadable/component'

const root = document.getElementById('root')

if (root.hasChildNodes() === true) {
  loadableReady(() => hydrate(<Container />, root))
} else {
  render(<Container />, root)
}
