import React from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import noop from 'lodash/noop'

import {
  Container,
  ImageBg,
  OverlayBg,
  TitleHolder,
  Title,
  TitleBg,
} from './styles'

const Location = ({ location, color, roundedBorder, onClick }) => {
  const handleClick = () => onClick(location)

  return (
    <Container
      bgurl={get(location, 'image_url')}
      roundedborder={roundedBorder}
      onClick={handleClick}
    >
      <ImageBg src={get(location, 'image_url')} />
      <OverlayBg />
      <TitleHolder>
        <Title>{get(location, 'region_name')}</Title>
        <TitleBg color={color} />
      </TitleHolder>
    </Container>
  )
}

Location.defaultProps = {
  location: {},
  color: '',
  roundedBorder: 0,
  onClick: noop,
}

Location.propTypes = {
  color: PropTypes.string,
  location: PropTypes.object,
  roundedBorder: PropTypes.number,
  onClick: PropTypes.func,
}

export default Location
