import React, { useCallback, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import PropTypes from 'prop-types'

import map from 'lodash/map'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'

import { useBaseTheme } from 'Hooks'
import { _ } from 'Services/I18n'
import { SEARCH_TYPE } from 'Constants/ids'
import { Radio } from 'Components/UI'

import {
  SortOptionsContainer,
  SortIcon,
  ActiveDot,
  SortOptionsModal,
  SortOptionsModalContent,
  Title,
  RadioHolder,
} from './styles'

const SearchSortOptions = ({ onChange }) => {
  const { primaryColor, showReviews } = useBaseTheme()
  const history = useHistory()
  const match = useRouteMatch()
  const [showDetailsModal, setShowDetailsModal] = useState(false)

  const isBusinessSearch =
    get(match, 'params.searchType') === SEARCH_TYPE.BUSINESSES

  const onClose = useCallback(() => {
    setShowDetailsModal(false)
  }, [])

  const onOptionSelect = useCallback(option => {
    setShowDetailsModal(false)

    onChange(option)
  }, [])

  // If the client has reviews disabled, don't show the sort feature for business search
  if (isBusinessSearch && !showReviews) {
    return null
  }

  const defaultOption = {
    label: _('sortBy.mostRelevant'),
    sort: '',
    order: 'asc',
  }

  const merchantOptions = [
    defaultOption,
    {
      label: `${_('sortBy.rating')} ${_('sortBy.desc')}`,
      sort: 'rating',
      order: 'desc',
    },
    {
      label: `${_('sortBy.rating')} ${_('sortBy.asc')}`,
      sort: 'rating',
      order: 'asc',
    },
  ]

  const serviceOptions = [
    defaultOption,
    {
      label: `${_('sortBy.price')} ${_('sortBy.asc')}`,
      sort: 'minimum_price',
      order: 'asc',
    },
    {
      label: `${_('sortBy.price')} ${_('sortBy.desc')}`,
      sort: 'minimum_price',
      order: 'desc',
    },
  ]

  const getSelectedOptionFromHistory = () => {
    const historyLocation = get(history, 'location', {})

    if (!historyLocation.state) {
      return defaultOption
    }

    const { sortBy, descOrAsc } = get(history, 'location.state', {})

    // Reset if not found
    if (!sortBy || !descOrAsc) {
      return defaultOption
    }

    const searchOptions = isBusinessSearch ? merchantOptions : serviceOptions

    // Return the value if found in the current search type's options
    return (
      searchOptions.find(
        option => option.sort === sortBy && option.order === descOrAsc,
      ) || defaultOption
    )
  }

  const selectedOption = getSelectedOptionFromHistory()

  const sortOptions = isBusinessSearch ? merchantOptions : serviceOptions

  return (
    <>
      <SortOptionsContainer onClick={() => setShowDetailsModal(true)}>
        <SortIcon />
        {_('search.sort')}
        {selectedOption.sort && <ActiveDot bg={primaryColor} />}
      </SortOptionsContainer>
      <SortOptionsModal isOpen={showDetailsModal} onCallback={onClose}>
        <SortOptionsModalContent>
          <Title>{_('sortBy.by')}</Title>
          <RadioHolder>
            {map(sortOptions, option => (
              <Radio
                checked={isEqual(selectedOption, option)}
                color={primaryColor}
                input={{
                  value: `${option.sort}|${option.order}`,
                  onChange: () => onOptionSelect(option),
                }}
                key={option.label}
                label={option.label}
                name="sort-options"
              />
            ))}
          </RadioHolder>
        </SortOptionsModalContent>
      </SortOptionsModal>
    </>
  )
}

SearchSortOptions.defaultProps = {}

SearchSortOptions.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default SearchSortOptions
