import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import {
  getIsLoadingServicesAvailability,
  getSearchDate,
} from 'Store/Selectors/marketplace'

import { setSearchDatePickerOpenStatus } from 'Store/Actions/marketplace'

import ServiceAvailability from './ServiceAvailability'

const selector = createStructuredSelector({
  availabilityDate: getSearchDate,
  isAvailabilityLoading: getIsLoadingServicesAvailability,
})

const actions = {
  setDatePickerOpenStatus: setSearchDatePickerOpenStatus,
}

export default connect(selector, actions)(ServiceAvailability)
