import React from 'react'
import PropTypes from 'prop-types'
import momentPropTypes from 'react-moment-proptypes'

import { _ } from 'Services/I18n'

import { SERVICE_AVAILABILITY } from 'Constants/ids'

import {
  AvailabilityWrapper,
  AvailabilityLabel,
  AvailabilityDateLabel,
  ShowDatePickerLabel,
  DatePickerIcon,
} from './styles'

const ServiceAvailability = ({
  availabilityDate,
  availability,
  isAvailabilityLoading,
  setDatePickerOpenStatus,
  onShowAvailability,
  ...rest
}) => {
  const handleShowDatePicker = event => {
    event.preventDefault()
    event.stopPropagation()

    setDatePickerOpenStatus({ focused: true })
  }

  const handleShowAvailability = event => {
    event.preventDefault()
    event.stopPropagation()

    onShowAvailability()
  }

  const getAvailabilityColor = () => {
    switch (availability) {
      case SERVICE_AVAILABILITY.AVAILABLE:
      case SERVICE_AVAILABILITY.BOOKING_REQUEST:
        return '#16A34A'
      default:
        return '#52525B'
    }
  }

  if (!availabilityDate) {
    return (
      <AvailabilityWrapper alignItems="stretch" {...rest}>
        <ShowDatePickerLabel onClick={handleShowDatePicker}>
          <DatePickerIcon color="#52525B" />

          {_('common.checkAvailability')}
        </ShowDatePickerLabel>
      </AvailabilityWrapper>
    )
  }

  return (
    <AvailabilityWrapper {...rest}>
      <AvailabilityLabel color={getAvailabilityColor()}>
        <DatePickerIcon color="#52525B" />
        {isAvailabilityLoading ? 'Searching...' : availability}
      </AvailabilityLabel>
      <AvailabilityDateLabel onClick={handleShowAvailability}>
        {availabilityDate.format('ddd D MMM')}
      </AvailabilityDateLabel>
    </AvailabilityWrapper>
  )
}

ServiceAvailability.defaultProps = {
  availability: '',
  availabilityDate: null,
  isAvailabilityLoading: false,
}

ServiceAvailability.propTypes = {
  availability: PropTypes.string,
  availabilityDate: momentPropTypes.momentObj,
  isAvailabilityLoading: PropTypes.bool,
  setDatePickerOpenStatus: PropTypes.func.isRequired,
  onShowAvailability: PropTypes.func.isRequired,
}

export default ServiceAvailability
