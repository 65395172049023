import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { loadCategoriesTags } from 'Store/Actions/marketplace'

import {
  getCategoriesTags,
  getLoadingCategoriesTags,
} from 'Store/Selectors/marketplace'

import MatchCategories from './MatchCategories'

const selector = createStructuredSelector({
  categoriesTags: getCategoriesTags,
  isLoadingCategoriesTags: getLoadingCategoriesTags,
})

const actions = {
  onLoadCategoriesTags: loadCategoriesTags,
}

export default connect(selector, actions)(MatchCategories)
