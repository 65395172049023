import React from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import noop from 'lodash/noop'

import {
  Container,
  ImageBg,
  OverlayBg,
  TitleHolder,
  Title,
  TitleBg,
  Wrapper,
} from './styles'

const Category = ({
  category,
  circleBorder,
  color,
  roundedBorder,
  onCategory,
}) => {
  const renderSquare = () => {
    return (
      <Wrapper onClick={onCategory(category)}>
        <Container
          bgurl={get(category, 'image_url')}
          roundedborder={roundedBorder}
        >
          <ImageBg src={get(category, 'image_url')} />
          <OverlayBg />
          <TitleHolder position="absolute">
            <Title color="white">{get(category, 'bucket_name')}</Title>
            <TitleBg color={color} />
          </TitleHolder>
        </Container>
      </Wrapper>
    )
  }

  const renderCircular = () => {
    return (
      <Wrapper onClick={onCategory(category)}>
        <Container
          bgurl={get(category, 'image_url')}
          circleborder={circleBorder}
          roundedborder={roundedBorder}
        >
          <ImageBg src={get(category, 'image_url')} />
          <OverlayBg />
        </Container>
        <TitleHolder align="center" customheight={60}>
          <Title color={color}>{get(category, 'bucket_name')}</Title>
        </TitleHolder>
      </Wrapper>
    )
  }

  return circleBorder ? renderCircular() : renderSquare()
}

Category.defaultProps = {
  category: {},
  circleBorder: 0,
  color: '',
  roundedBorder: 0,
  onCategory: noop,
}

Category.propTypes = {
  category: PropTypes.object,
  circleBorder: PropTypes.number,
  color: PropTypes.string,
  roundedBorder: PropTypes.number,
  onCategory: PropTypes.func,
}

export default Category
