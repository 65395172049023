import React from 'react'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import noop from 'lodash/noop'
import map from 'lodash/map'

import { _ } from 'Services/I18n'

import { useBaseTheme } from 'Hooks'

import { PUBLIC_PATHS } from 'Constants/paths'

import Categories from '../DefaultCategories/Categories'
import RatingStars from '../RatingStars'
import ServiceCustomFilterOptions from '../ServiceCustomFilterOptions'
import {
  Description,
  Labels,
  Location,
  Price,
  Title,
} from '../ServiceCard/Components'

import {
  DetailsModal,
  DetailsModalContent,
  DetailsModalTopWrapper,
  DetailsModalTopContent,
  DetailsModalButtonHolder,
  StyledButton,
  StyledButtonAlt,
} from './styles'

const ServiceDetailsModal = ({
  business,
  categories,
  locations,
  reviews,
  service,
  showGoToService,
  onBook,
  onClose,
}) => {
  const { isPortableDevice, primaryColor, primaryTextColor, roundedBorder } =
    useBaseTheme()

  const history = useHistory()

  const appended = get(service, 'appended', false)
  const truncateLines = isPortableDevice ? 5 : 10
  const description = get(service, 'description', '')
  const customFilters = get(service, 'client_custom_filters', [])

  const handleGoToService = () => {
    history.push(
      PUBLIC_PATHS.SERVICE({
        id: get(service, 'token'),
        slug: get(service, 'slug'),
      }),
      {
        ...history.location.state,
        prevPath: history.location.pathname,
      },
    )
  }

  return (
    <>
      <DetailsModal isOpen onCallback={onClose}>
        <DetailsModalContent>
          <DetailsModalTopWrapper>
            <DetailsModalTopContent>
              <Title
                businessName={get(business, 'name')}
                serviceName={get(service, 'name')}
              />
              {reviews.total > 0 && (
                <RatingStars
                  businessName={get(business, 'name')}
                  color="#52525B"
                  display
                  reviews={reviews}
                  size={14}
                />
              )}
              <Location locations={locations} online={appended} />
              <Labels service={service} />
              <Price flexDirection="row" service={service} />
            </DetailsModalTopContent>
            <DetailsModalButtonHolder>
              <>
                <StyledButton
                  background={primaryColor}
                  color={primaryTextColor}
                  roundedborder={roundedBorder}
                  onClick={onBook}
                >
                  {_('common.bookNow')}
                </StyledButton>
                {showGoToService && (
                  <StyledButtonAlt
                    background={primaryColor}
                    color={primaryTextColor}
                    roundedborder={roundedBorder}
                    onClick={handleGoToService}
                  >
                    {_('common.findMore')}
                  </StyledButtonAlt>
                )}
              </>
            </DetailsModalButtonHolder>
          </DetailsModalTopWrapper>

          <Description
            description={description}
            truncateLines={truncateLines}
          />

          <Categories categories={categories} />

          {map(customFilters, customFilter => {
            return (
              <ServiceCustomFilterOptions
                customFilter={customFilter}
                key={customFilter.id}
              />
            )
          })}
        </DetailsModalContent>
      </DetailsModal>
    </>
  )
}

ServiceDetailsModal.defaultProps = {
  categories: [],
  locations: [],
  reviews: {},
  showGoToService: true,
  onBook: noop,
  onClose: noop,
}

ServiceDetailsModal.propTypes = {
  business: PropTypes.object.isRequired,
  categories: PropTypes.array,
  locations: PropTypes.array,
  reviews: PropTypes.object,
  service: PropTypes.object.isRequired,
  showGoToService: PropTypes.bool,
  onBook: PropTypes.func,
  onClose: PropTypes.func,
}

export default ServiceDetailsModal
