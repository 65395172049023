import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { getSearchDate } from 'Store/Selectors/marketplace'

import ServiceRow from './ServiceRow'

const selector = createStructuredSelector({
  availabilityDate: getSearchDate,
})

const actions = {}

export default connect(selector, actions)(ServiceRow)
