import styled from 'styled-components'
import Slider from 'react-slick'

import { Box, Flex } from 'Components/UI'

export const Container = styled(Flex)`
  display: grid;
  grid-gap: 32px;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  width: 100%;
`

export const FeaturedCategoriesContainer = styled(Box).attrs({
  display: ['none', 'none', 'block', 'block'],
})`
  width: 100%;
`

export const FeaturedCategoriesMobileContainer = styled(Box).attrs({
  display: ['block', 'block', 'none', 'none'],
})`
  overflow: hidden;
  width: 100%;
`
export const FeaturedCategoriesMobileSlider = styled(Slider).attrs({})``
