import { getReactEnv } from 'Services/Envs'

export const APP_ENV = getReactEnv('ENV')

export const IS_PRODUCTION = APP_ENV === 'production'

const apiHttps = getReactEnv('API_HTTPS') === 'true' ? 'https' : 'http'

export const API = {
  URL: `${apiHttps}://${getReactEnv('API_URL')}/api`,
}

export const COOKIE = {
  SHARED_DOMAIN: `${getReactEnv('COOKIE_DOMAIN')}`,
  PREFIX: 'nabooki',
  PERSIST_KEY: `nabooki-persist${!IS_PRODUCTION ? `-${APP_ENV}` : ''}`,
}

export const APPEARANCE = {
  THEME: 'main',
}

export const GOOGLE_MAP = {
  KEY: getReactEnv('GOOGLE_MAP_KEY'),
}

export const GA = {
  KEY: getReactEnv('GA'),
}

export const SENTRY_DSN = getReactEnv('SENTRY_DSN')

export default {
  APP_ENV,
  IS_PRODUCTION,
}
