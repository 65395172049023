import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import { mapToTheme } from 'styled-map'
import { Flex, Icon, Box } from 'Components/UI'
import { calendarGlyph } from 'Assets/Svg'

export const AvailabilityWrapper = styled(Flex).attrs(({ alignItems }) => ({
  alignItems: alignItems || 'center',
}))`
  gap: 6px;
`

export const AvailabilityLabel = styled(Flex).attrs({})`
  line-height: normal;
  gap: 4px;
  color: ${({ color }) => color || mapToTheme('colors.white')};
  text-align: center;
  font-weight: 700;
  font-size: 12px;
  border-radius: ${themeGet('borderRadius.1')};
`

export const AvailabilityDateLabel = styled(Box).attrs({})`
  text-decoration: underline;
  cursor: pointer;
  font-size: 12px;

  &:hover {
    text-decoration: none;
  }
`

export const ShowDatePickerLabel = styled(Flex).attrs({
  alignItems: 'center',
})`
  gap: 4px;
  text-decoration: underline;
  cursor: pointer;
  font-size: 12px;

  &:hover {
    text-decoration: none;
  }
`

export const DatePickerIcon = styled(Icon).attrs({
  glyph: calendarGlyph,
  size: 14,
})``
