import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Box } from 'Components/UI'

export const Container = styled(Box)`
  user-select: none;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  &:hover {
    z-index: 2;
  }
`

const activeCss = props =>
  props.active &&
  css`
    background-color: ${({ fill }) => fill} !important;
    color: ${({ color }) => color || themeGet('colors.tealBlue')};
    max-width: fit-content;
    border-color: ${({ fill }) => fill};
  `

export const MapMarker = styled(Box).attrs({
  px: '10px',
  py: '8px',
  fontSize: 14,
})`
  background-color: ${themeGet('colors.white')};
  border-radius: ${themeGet('borderRadius.1')};
  border: 1px solid #b6b6b6;
  box-shadow: 1px 3px 10px 0px rgba(0, 0, 0, 0.25);

  color: ${themeGet('colors.header')};
  font-weight: 700;
  text-align: center;
  cursor: pointer;

  max-width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &:hover {
    max-width: fit-content;
    background-color: #e4e4e4;
  }

  ${activeCss}
`

export default {}
