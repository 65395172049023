import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import {
  setSearchDate,
  loadServicesAvailability,
} from 'Store/Actions/marketplace'

import { getMerchant, getSearchDate } from 'Store/Selectors/marketplace'

import GroupedServices from './GroupedServices'

const selector = createStructuredSelector({
  merchant: getMerchant,
  searchDate: getSearchDate,
})

const actions = {
  onLoadServicesAvailability: loadServicesAvailability,
  onSetSearchDate: setSearchDate,
}

export default connect(selector, actions)(GroupedServices)
