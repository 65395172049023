import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import noop from 'lodash/noop'

import { isFreeService, getPrice } from 'Services/Utils/service'
import { _ } from 'Services/I18n'

import { Container, MapMarker } from './styles'

class Marker extends PureComponent {
  handleClick = () => {
    const { marker, onClick } = this.props
    onClick(marker)
  }

  getMarkerLabel = () => {
    const { marker } = this.props

    if (marker.name) {
      return marker.name
    }

    const priceMinimum = getPrice(marker.service)

    if (!priceMinimum) {
      return '$'
    }

    // Free service
    if (isFreeService(marker.service)) {
      return _('service.free')
    }

    return priceMinimum || '$'
  }

  render() {
    const { active, baseTheme } = this.props

    return (
      <Container>
        <MapMarker
          active={active ? 1 : 0}
          color={get(baseTheme, 'colors.primary_text')}
          fill={get(baseTheme, 'colors.primary_background')}
          onClick={this.handleClick}
        >
          {this.getMarkerLabel()}
        </MapMarker>
      </Container>
    )
  }
}

Marker.defaultProps = {
  onClick: noop,
}

Marker.propTypes = {
  active: PropTypes.bool.isRequired,
  baseTheme: PropTypes.object.isRequired,
  marker: PropTypes.object.isRequired,
  onClick: PropTypes.func,
}

export default Marker
