import React from 'react'
import styled from 'styled-components'
import {
  space,
  width,
  height,
  fontSize,
  color,
  maxWidth,
  minWidth,
  zIndex,
  position,
  display,
} from 'styled-system'
import Tag from 'clean-tag'

import omit from 'lodash/omit'

const blacklist = [
  'minWidth',
  'maxWidth',
  'fontSize',
  'zIndex',
  'active',
  'isNextType',
  'textAlign',
  'staticContext',
  'match',
  'history',
  'isMultiValue',
  'closeColor',
  'diffWidth',
  'themeName',
  'cookiesAccepted',
]

const Box = styled(
  React.forwardRef((props, ref) => (
    <Tag ref={ref} {...omit(props, blacklist)} />
  )),
).attrs({
  blacklist,
})`
  ${space}
  ${width}
  ${height}
  ${fontSize}
  ${color}
  ${maxWidth}
  ${minWidth}
  ${zIndex}
  ${position}
  ${display}
`

Box.displayName = 'Box'

export default Box
