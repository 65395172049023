import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Flex } from 'Components/UI'

export default styled(Flex)`
  min-width: 250px;

  .SingleDatePickerInput {
    border: 1px solid #f3f3f3;

    .DateInput {
      input {
        @media screen and (max-width: ${themeGet('breakpoints.1')}) {
          height: 100%;
          padding: 0 7px 0 0;
          margin-bottom: -5px;
        }
      }
    }
  }
`
