import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import size from 'lodash/size'

import { withAppContext } from 'Services/Context'
import * as Utils from 'Services/Utils'

import Filter from 'Components/Blocks/Filter'
import FilterDropmenu from 'Components/Blocks/FilterDropmenu'

import {
  processFeaturedCategories,
  processBuildCategoryValue,
} from 'Services/Utils/merchants'

import {
  Container,
  DesktopContent,
  MobileContent,
  FilterDropmenuHolder,
} from './styles'

function FilterCategories({
  baseTheme,
  categories,
  categoryValue,
  onSetCategoryValue,
}) {
  const handleChangeCategoryFilter = useCallback(
    selectedCategory => {
      onSetCategoryValue(selectedCategory)
    },
    [categoryValue],
  )

  const items = useMemo(
    () =>
      processFeaturedCategories({
        categories,
        type: 'categories',
      }).options,
    [categories],
  )
  const value = useMemo(
    () => processBuildCategoryValue(categoryValue, categories),
    [categoryValue, categories],
  )
  const bucketId = useMemo(
    () =>
      get(categoryValue, 'value.bucket_id') || get(categoryValue, 'bucket_id'),

    [categoryValue],
  )

  const secondaryColor = get(baseTheme, 'colors.secondary_background')

  if (!size(categories)) {
    return null
  }

  const { categoryFullName } = Utils.getCategoryProps(value)

  return (
    <Container>
      <MobileContent>
        <FilterDropmenuHolder>
          <FilterDropmenu
            closeOnClick
            color={secondaryColor}
            items={items}
            placeholder={categoryFullName}
            showBadge={false}
            value={value}
            onSelect={handleChangeCategoryFilter}
          />
        </FilterDropmenuHolder>
      </MobileContent>
      <DesktopContent>
        <Filter
          bucketId={bucketId}
          items={items}
          limit={10}
          type="radio"
          value={value}
          onSelect={handleChangeCategoryFilter}
        />
      </DesktopContent>
    </Container>
  )
}

FilterCategories.defaultProps = {
  categories: [],
  categoryValue: null,
}

FilterCategories.propTypes = {
  baseTheme: PropTypes.object.isRequired,
  categories: PropTypes.array,
  categoryValue: PropTypes.object,
  onSetCategoryValue: PropTypes.func.isRequired,
}

export default withAppContext(FilterCategories)
