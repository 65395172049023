import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { closeGlyph } from 'Assets/Svg'

import { Text, Flex, Box, Icon, Button } from 'Components/UI'

export const Container = styled(Box).attrs({})`
  position: relative;
`

export const ContentHolderWrapper = styled(Box).attrs({
  id: 'cardContentHolderWrapper',
  padding: [2, 2, '12px'],
})`
  width: 100%;
  border-radius: ${({ roundedborder }) =>
    roundedborder ? themeGet('borderRadius.2') : themeGet('borderRadius.1')};
  overflow: hidden;
  border: 1px solid ${themeGet('colors.lightGrey')};
  background-color: white;

  transform: none;
  transition:
    all 0.2s ease 0s,
    -webkit-transform 0.2s ease 0s;

  &:hover {
    transform: scale(1.02);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 9px 2px;
    transition:
      all 0.2s ease 0s,
      -webkit-transform 0.2s ease 0s;
  }
`

export const ContentHolder = styled(Flex).attrs({
  flexDirection: ['column', 'column', 'row'],
  id: 'cardContentHolder',
})`
  background-color: ${themeGet('colors.white')};
  width: 100%;
  min-height: 171px;
  position: relative;

  @media screen and (max-width: ${themeGet('breakpoints.1')}) {
    height: 430px;
    height: unset;
  }
`

export const ImageHolder = styled(Box).attrs({
  id: 'imageHolder',
})`
  height: 171px;
  width: 241px;
  min-width: 241px;
  position: relative;
  @media screen and (max-width: ${themeGet('breakpoints.1')}) {
    height: 231px;
    width: 100%;
    min-width: 215px;
  }
  @media screen and (max-width: ${themeGet('breakpoints.0')}) {
    height: 310px;
    width: 100%;
  }
`

export const MainContentHolder = styled(Flex).attrs({
  flexDirection: 'column',
  id: 'mainContentHolder',
  pl: [0, 0, 12],
})`
  flex: 1 1 auto;
  min-width: 0;
  position: relative;
`

export const MainContent = styled(Flex).attrs({
  width: 1,
  flex: 1,
})``

export const InnerImageHolder = styled(Box).attrs({
  pb: [20, 20, 0],
  pr: 2,
})`
  height: 100px;
  width: 200px;
  min-width: 200px;
  position: relative;

  @media screen and (max-width: 1024px) {
    height: 100px;
    min-width: 200px;
    width: 200px;
  }

  @media screen and (max-width: ${themeGet('breakpoints.0')}) {
    width: 100px;
    min-width: 100px;
  }
`

export const ImageBg = styled(Box).attrs({})`
  width: 100%;
  height: 100%;
  background: url(${({ src }) => src}) center no-repeat;
  background-size: cover;
  border-radius: 4px;

  @media screen and (max-width: ${themeGet('breakpoints.1')}) {
    border-radius: ${({ roundedborder }) =>
      roundedborder ? themeGet('borderRadius.2') : '4px'};

    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  @media screen and (min-width: ${themeGet(
      'breakpoints.0',
    )}) and (max-width: ${themeGet('breakpoints.1')}) {
    background-size: contain;
  }
`

export const Image = styled(Box).attrs({})`
  width: 100%;
  height: 100%;
  background: url(${({ src }) => src}) no-repeat center center / cover
    content-box;
  cursor: pointer;
`

export const ContentWrapper = styled(Flex).attrs({
  flexDirection: ['column', 'column', 'row'],
  justifyContent: 'space-between',
  width: 1,
})`
  min-width: 0;
`

export const Content = styled(Flex).attrs({
  flexDirection: 'column',
  justifyContent: 'space-between',
})`
  min-width: 0;
  width: 100%;
`

export const Title = styled(Text).attrs({
  fontSize: 16,
  pt: [3, 3, 0],
})`
  font-weight: bold;
  letter-spacing: 0.6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  color: ${themeGet('colors.header')};
  line-height: 1.25;

  width: ${({ withlogo }) =>
    withlogo ? 'calc(100% - 120px)' : 'calc(100% - 30px)'};

  @media screen and (max-width: ${themeGet('breakpoints.2')}) {
    width: calc(100% - 30px);
  }
`

export const Location = styled(Flex).attrs({
  justifyContent: 'flex-start',
  alignItems: 'center',
})`
  flex-wrap: nowrap;
  white-space: nowrap;
`

export const LocationText = styled(Text).attrs({
  fontSize: 14,
})`
  letter-spacing: 0.4px;
  color: ${themeGet('colors.text')};
  line-height: 1.25;
`

export const LocationIcon = styled(Icon).attrs({
  size: 14,
  mr: 1,
})`
  color: ${props =>
    props.selected
      ? themeGet('colors.white')
      : props.color || themeGet('colors.tealBlue')};
  margin-top: -3px;
`

const roundedborderButtonCss = ({ roundedborder }) =>
  roundedborder &&
  css`
    border-radius: ${themeGet('borderRadius.2')} !important;
    overflow: hidden;
  `

export const StyledButton = styled(Button)`
  background-color: ${({ background }) =>
    background || themeGet('colors.goldenrod')};
  border-radius: ${themeGet('borderRadius.1')};
  color: ${({ color }) => color || themeGet('colors.black')};
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  width: 215px;
  min-width: 120px;
  white-space: nowrap;
  @media screen and (max-width: 1265px) {
    width: 100%;
  }
  ${roundedborderButtonCss};
`

export const Close = styled(Icon).attrs({
  glyph: closeGlyph,
  size: 16,
})`
  position: absolute;
  right: ${({ roundedborder }) => (roundedborder ? '15px' : '10px')};
  top: 10px;
  cursor: pointer;
  fill: ${({ closeColor }) => closeColor && themeGet(`colors.${closeColor}`)};
  z-index: 1;
`

export const ButtonHolder = styled(Flex).attrs({
  display: ['none', 'none', 'flex'],
  alignItems: 'flex-end',
  pl: [0, 0, 0, 24],
  pr: 0,
  id: 'buttonHolder',
})`
  > button {
    border-radius: ${({ roundedborder }) =>
      roundedborder ? themeGet('borderRadius.2') : themeGet('borderRadius.1')};
    overflow: hidden;
  }
`

const buttonPositionAbsoluteCss = ({ position }) =>
  position === 'absolute' &&
  css`
    @media screen and (max-width: ${themeGet('breakpoints.1')}) {
      bottom: unset;
      top: 148px;
      left: 10px;
      right: 0;
    }
  `

const buttonPositionCss = ({ position }) =>
  position &&
  css`
    position: ${position};
    width: width: calc(100vw - 20px);
  `

export const MobileButtonHolder = styled(ButtonHolder).attrs({
  display: ['flex', 'flex', 'none', 'none'],
  id: 'mobileButtonHolder',
})`
  position: relative;
  height: 48px;
  bottom: 0;
  ${buttonPositionCss};
  ${buttonPositionAbsoluteCss};
`

export const MerchantLogo = styled.img`
  max-height: 58px;
  max-width: 80px;
  position: absolute;
  top: 0;
  right: 0;
  @media screen and (max-width: ${themeGet('breakpoints.2')}) {
    display: none;
  }
`
