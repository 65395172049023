import React from 'react'
import PropTypes from 'prop-types'
import momentPropTypes from 'react-moment-proptypes'

import map from 'lodash/map'

import { useBaseTheme } from 'Hooks'

import { Box } from 'Components/UI'

import ServiceList from '../ServiceList'
import SearchDatePicker from '../SearchDatePicker'

import DatePickerWrapper from './styles'

function GroupedServices({
  onLoadServicesAvailability,
  onSetSearchDate,
  searchDate,
  services,
  merchant,
}) {
  const { showAvailability } = useBaseTheme()

  const handleLoadServiceAvailability = date => {
    if (!date) {
      return
    }

    const servicesIds = map(services, 'id').slice(0, 30)

    if (!servicesIds.length) {
      return
    }

    onLoadServicesAvailability({
      date: date.format('YYYY-MM-DD'),
      services: servicesIds,
    })
  }

  const handleSearchDateChange = date => {
    if (date && date.isSame(searchDate)) {
      return
    }

    onSetSearchDate(date)

    handleLoadServiceAvailability(date)
  }

  return (
    <>
      <Box>
        {showAvailability && (
          <DatePickerWrapper>
            <SearchDatePicker
              date={searchDate}
              small
              onDateChange={handleSearchDateChange}
            />
          </DatePickerWrapper>
        )}
      </Box>
      <Box mt={2}>
        <ServiceList merchant={merchant} services={services} />
      </Box>
    </>
  )
}

GroupedServices.defaultProps = {
  merchant: null,
  services: [],
  searchDate: null,
}

GroupedServices.propTypes = {
  merchant: PropTypes.object,
  searchDate: momentPropTypes.momentObj,
  services: PropTypes.array,
  onLoadServicesAvailability: PropTypes.func.isRequired,
  onSetSearchDate: PropTypes.func.isRequired,
}

export default GroupedServices
