import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useLocation, useHistory } from 'react-router'

import get from 'lodash/get'
import map from 'lodash/map'
import filter from 'lodash/filter'

import * as Utils from 'Services/Utils'
import { withAppContext } from 'Services/Context'
import { PUBLIC_PATHS } from 'Constants/paths'

import { Select } from 'Components/UI'

import {
  SearchContainer,
  InputContainer,
  FindButton,
  SearchIcon,
} from './styles'

function CategoriesSearchByTags({
  baseTheme,
  categoriesTags,
  customPlaceholder,
  isLoadingCategoriesTags,
  onLoadCategoriesTags,
}) {
  const history = useHistory()
  const location = useLocation()
  const [selectedTags, setSelectedTags] = useState(null)

  const tagOptions = useMemo(() => {
    return map(categoriesTags, tag => ({
      label: tag.name,
      value: tag.id,
      slug: tag.slug,
    }))
  }, [categoriesTags])

  const handleSelectTagsFromLocation = tagSlug => {
    if (tagSlug) {
      const tagValues = filter(tagOptions, tagOption =>
        tagSlug.includes(tagOption.slug),
      )
      setSelectedTags(tagValues)
    } else {
      setSelectedTags(null)
    }
  }

  const handleLoadCategoriesTags = () => {
    if (!categoriesTags.length && !isLoadingCategoriesTags) {
      onLoadCategoriesTags()
    }
  }

  useEffect(() => {
    const tagSlug = Utils.getTagSlugFromLocation(location)

    if (tagSlug) {
      handleLoadCategoriesTags()
    }

    handleSelectTagsFromLocation(tagSlug)
  }, [tagOptions, location.hash])

  const handleSelectedTags = value => {
    const tagSlug = get(value, 'slug', null)
    let tagsHash = ''

    if (tagSlug) {
      tagsHash = `#tags=${tagSlug}`
    }

    history.push(`${PUBLIC_PATHS.CATEGORIES}${tagsHash}`)

    setSelectedTags(value)
  }

  const data = useMemo(() => {
    const primaryBackground = get(baseTheme, 'colors.primary_background')
    const secondaryBackground = get(baseTheme, 'colors.secondary_background')
    const primaryColor = get(baseTheme, 'colors.primary_text')
    const roundedBorder = get(baseTheme, 'colors.rounded_border') ? 1 : 0
    const showSearchLabel = 1 // marketplaceServiceType && serviceCategory ? 1 : 0

    return {
      primaryBackground,
      secondaryBackground,
      primaryColor,
      roundedBorder,
      showSearchLabel,
    }
  }, [baseTheme])
  const {
    primaryBackground,
    secondaryBackground,
    primaryColor,
    roundedBorder,
    showSearchLabel,
  } = data

  return (
    <SearchContainer>
      <InputContainer borderradius={roundedBorder} color={secondaryBackground}>
        <Select
          closeMenuOnSelect
          color={secondaryBackground}
          isClearable
          isLoading={isLoadingCategoriesTags}
          isMulti={false}
          options={tagOptions}
          placeholder={customPlaceholder}
          roundedborder={roundedBorder}
          value={selectedTags}
          onChange={handleSelectedTags}
          onFocus={handleLoadCategoriesTags}
        />
      </InputContainer>
      <FindButton
        bgcolor={primaryBackground}
        borderradius={roundedBorder}
        textcolor={primaryColor}
        withlabel={showSearchLabel}
      >
        <SearchIcon withlabel={showSearchLabel} />
      </FindButton>
    </SearchContainer>
  )
}

CategoriesSearchByTags.defaultProps = {
  customPlaceholder: 'Search',
}

CategoriesSearchByTags.propTypes = {
  baseTheme: PropTypes.object.isRequired,
  categoriesTags: PropTypes.array.isRequired,
  customPlaceholder: PropTypes.string,
  isLoadingCategoriesTags: PropTypes.bool.isRequired,
  onLoadCategoriesTags: PropTypes.func.isRequired,
}

export default withAppContext(CategoriesSearchByTags)
