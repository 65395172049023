import { createSelector } from 'reselect'
import moment from 'moment'

export const getState = state => state.marketplace

export const getFeaturedMerchants = createSelector(
  getState,
  state => state.featuredMerchants,
)

export const getFeaturedMerchantsLoading = createSelector(
  getState,
  state => state.isFeaturedMerchantsLoading,
)

export const getSearchResults = createSelector(
  getState,
  state => state.searchResults,
)

export const getSearchError = createSelector(
  getState,
  state => state.searchError,
)

export const getIsSearchLoading = createSelector(
  getState,
  state => state.isSearchLoading,
)

export const getMerchant = createSelector(getState, state => state.merchant)

export const getMerchantError = createSelector(
  getState,
  state => state.merchantError,
)

export const getSsrMerchantPreloaded = createSelector(
  getState,
  state => state.ssrMerchantPreloaded,
)

export const getNearBucket = createSelector(getState, state => state.nearBucket)

export const isLoadingMerchant = createSelector(
  getState,
  state => state.isMerchantLoading,
)

export const getWidgetToken = createSelector(
  getState,
  state => state.widgetToken,
)

export const getFeaturedCategories = createSelector(
  getState,
  state => state.featuredCategories,
)

export const getFeaturedLocations = createSelector(
  getState,
  state => state.featuredLocations?.data,
)

export const getFeaturedCategoriesLoading = createSelector(
  getState,
  state => state.isFeaturedCategoriesLoading,
)

export const getFeaturedLocationsLoading = createSelector(
  getState,
  state => state.isFeaturedLocationsLoading,
)

export const getCategoryValue = createSelector(
  getState,
  state => state.categoryValue,
)

export const getSearchValue = createSelector(
  getState,
  state => state.searchValue,
)

export const getSearchDate = createSelector(getState, state =>
  state.searchDate ? moment.unix(state.searchDate) : null,
)

export const getSearchDatePickerOpenStatus = createSelector(
  getState,
  state => state.isSearchDatePickerOpen,
)

export const getCategories = createSelector(getState, state => state.categories)

export const getLoadingCategories = createSelector(
  getState,
  state => state.isLoadingCategories,
)

export const getCategory = createSelector(getState, state => state.category)

export const getLoadingCategory = createSelector(
  getState,
  state => state.isLoadingCategory,
)

export const getSsrPreloadedCategory = createSelector(
  getState,
  state => state.ssrPreloadedCategory,
)

export const getAllCategories = createSelector(
  getState,
  state => state.allCategories,
)

export const getLoadingAllCategories = createSelector(
  getState,
  state => state.isLoadingAllCategories,
)

export const getSsrPreloadedAllCategories = createSelector(
  getState,
  state => state.ssrPreloadedAllCategories,
)

export const getCategoriesTags = createSelector(
  getState,
  state => state.categoriesTags,
)

export const getLoadingCategoriesTags = createSelector(
  getState,
  state => state.isLoadingCategoriesTags,
)

export const getServicesAvailability = createSelector(
  getState,
  state => state.servicesAvailability,
)

export const getIsLoadingServicesAvailability = createSelector(
  getState,
  state => state.isLoadingServicesAvailability,
)

export const getIsLoadingService = createSelector(
  getState,
  state => state.isLoadingService,
)

export const getService = createSelector(getState, state => state.service)

export const getServiceError = createSelector(
  getState,
  state => state.serviceError,
)
